"use client";
import { DatadogUser } from "services/datadog";
import { LoginGuard } from "services/auth/Authentication";
import React from "react";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <LoginGuard>
      <DatadogUser />
      {children}
    </LoginGuard>
  );
}
